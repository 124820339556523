<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Enroll in Workout Program</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <Form @submit="handleStartWorkoutPlan" v-slot="{ errors }">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Select Workout Program</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.workout }">
                                    <Field autocomplete="off" name="workout" v-model="form.ids" rules="required" label="workout">
                                        <multiselect
                                            v-model="form.ids"
                                            label="name"
                                            value-prop="id"
                                            :searchable="true"
                                            :options="workoutPlans"
                                            placeholder="Select Workout Program"
                                            mode="tags"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="workout" class="text-danger" />
                            </div>
                        </div>
                        <div class="starting_plan mt-5 mb-5 workout-plan-evergreen" v-if="form.ids.length">
                            <h4 class="mb-4">Choose a program assign method</h4>
                            <div class="flex-space-between">
                                <h5>Assign as evergreen <span class="workout-plan-badge">Easy to Scale</span></h5>
                                <label for="start_w1_w2" class="switch_option capsule_btn">
                                    <input type="radio" name="evergreen_option" id="start_w1_w2" :value="1"
                                        v-model="form.is_evergreen" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <p class="info-text mb-5">This workout program won't appear on your client's calendar. Instead, it will be available for on-demand completion in their programs until you set restrictions.</p>
                            <div class="flex-space-between mt-3">
                                <h5>Assign as date-based <span class="workout-plan-badge">Uses Calendar</span></h5>
                                <label for="start_w1_w3" class="switch_option capsule_btn">
                                    <input type="radio" name="evergreen_option" id="start_w1_w3" :value="0"
                                        v-model="form.is_evergreen" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <p class="info-text">This workout program will appear on your client's portal calendar and daily task list. This option also enabled client reminders.</p>
                            <div class="mt-3" v-if="form.is_evergreen == 0">
                                <div class="flex-space-between">
                                    <h5>Start on Week 1 Workout 1? </h5>
                                    <label for="start_w1_w1" class="switch_option capsule_btn">
                                        <input type="checkbox" id="start_w1_w1" :true-value="1" :false-value="0"
                                            v-model="form.start_type" hidden :disabled="form.ids.length > 1">
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div v-if="form.start_type">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Starting on:</label>
                                            <div class="field_wpr">
                                                <multiselect v-model="form.start_day" :options="startingDay"
                                                    placeholder="Select one"></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="extra_note" v-if="form.start_day == 0">
                                        Workout plan will be started on Monday - {{ moment(form.start_date).format('ll') }}
                                    </div>
                                    <div class="start_plan" v-if="form.start_day == 1">
                                        <date-picker v-model="form.start_date" :min-date='new Date()'
                                            :disabled-dates="disabledDays" :first-day-of-week="2" is-expanded />
                                    </div>
                                </div>
                                <div v-if="!form.start_type && form.ids.length == 1">
                                    <div class="flex-space-between">
                                        <h5>Start this week </h5>
                                        <label for="start_this_week" class="switch_option capsule_btn">
                                            <input type="checkbox" id="start_this_week" :true-value="1" :false-value="0"
                                                v-model="form.start_on_this_week" hidden :disabled="form.ids.length > 1">
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Select Starting Week</label>
                                            <div class="field_wpr">
                                                <multiselect v-model="form.start_week_id" :options="startingWeeks"
                                                    placeholder="Select one" value-prop="id" label="name"></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Select Starting Workout</label>
                                            <div class="field_wpr">
                                                <multiselect v-model="form.start_workout_id" :options="startingWorkouts"
                                                    placeholder="Select one" value-prop="id" label="name"></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-space-between mt-3" v-if="form.is_evergreen == 0">
                                <label for="start_w1_w4" class="switch_option capsule_btn">
                                    <h5>Show program in workout library</h5>
                                    <input type="checkbox" name="evergreen_option" id="start_w1_w4" :true-value="1" :false-value="0"
                                        v-model="form.show_in_library" hidden :disabled="form.ids.length > 1">
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="additional-options">
                                <h3><span>additional options</span></h3>
                            </div>
                            <div class="flex-space-between" v-show="form.contacts.length">
                                <h5>Add workouts to clients classroom. <span class="workout-plan-badge bagde-blue">Great Bonus</span></h5>
                                <label for="assign-plan-workouts" class="switch_option capsule_btn">
                                    <input type="checkbox" :true-value="1" :false-value="0" id="assign-plan-workouts" v-model="form.add_workouts_in_classroom" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <p class="info-text">The individual workouts in this program will also be added to your client's workout library to be completed on demand.</p>
                        </div>
                        <schedule-component :schedule-data="form" title="workout" :close-modal="closeModal" v-if="1 == 2" />
                        <div class="action_wpr mt-5">
                            <button :disabled="bulkActionLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="bulkActionLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="bulkActionLoader"></i>{{ bulkActionLoader ? ' Starting' : 'Start' }}</button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'
    import { DatePicker } from 'v-calendar'

    const ScheduleComponent = defineAsyncComponent(() => import('@/components/Schedule'))

    import moment from 'moment-timezone'
    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'

    export default {
        name: 'Start Workout',

        data () {
            return {
                form: {
                    contacts: '',
                    ids: [],
                    action: '/workout-plan/assign-to-contacts',
                    start_type: 1,
                    start_day: 0,
                    start_date: this.getNextMonday(),
                    start_week_id: 0,
                    start_workout_id: 0,
                    add_workouts_in_classroom: 1,
                    start_on_this_week: 0,
                    is_evergreen: 0,
                    show_in_library: 0,
                },
                startingDay: [
                    { value: 0, label: 'Start on next available Monday?' },
                    { value: 1, label: 'Start on a different Monday' },
                    { value: 2, label: 'Start this week' },
                ],
                startingWeeks: [],
                startingWorkouts: [],
                disabledDays: {
                    weekdays: [1, 3, 4, 5, 6, 7]
                },
                moment,
            }
        },

        props: {
            modelValue: Boolean,
            contacts: [Object, Array],
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue (val) {
                const vm = this;
                const startDate = vm.getNextMonday();

                if (val == true) {
                    vm.form.contacts = vm.contacts;
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

                if (vm.workoutPlans.length == 0) {
                    vm.getAllWorkoutPlans().then((result) => {
                        if (result) {
                            vm.form.start_date  = startDate;

                            if (vm.form.ids.length == 1) {
                                vm.parseWeeks();
                            }
                        }
                    });
                } else {
                    vm.form.start_date  = startDate;

                    if (vm.form.ids.length == 1) {
                        vm.parseWeeks();
                    }
                }
            },

            'form.start_week_id' (week) {
                const vm = this;

                vm.parseWorkouts(week);
            },

            'form.start_day' (day) {
                const vm = this;

                if (day == 0) {
                    vm.form.start_date  = vm.getNextMonday();
                }

                if (day == 2) {
                    vm.startOnThisWeekAlert();
                }
            },

            'form.ids' (ids) {
                const vm = this;

                if (ids.length == 1) {
                    vm.parseWeeks();
                }
            },

            'form.start_on_this_week' (val) {
                const vm = this;

                if (val)  {
                     vm.startOnThisWeekAlert();
                }
            }
        },

        components: {
            Form,
            Field,
            ErrorMessage,
            ScheduleComponent,
            DatePicker
        },

        computed: {
            ...mapState({
                workoutPlans: state => state.workoutModule.allWorkoutPlans,
                bulkActionLoader: state => state.contactModule.bulkActionLoader,
            }),
        },

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                getAllWorkoutPlans: 'workoutModule/getAllWorkoutPlans',
                startWorkoutPlan: 'workoutModule/startWorkoutPlan',
            }),

            closeModal () {
                const vm = this;

                vm.resetForm();
                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    contacts: '',
                    ids: [],
                    action: '/workout-plan/assign-to-contacts',
                    start_type: 1,
                    start_day: 0,
                    start_date: vm.getNextMonday(),
                    start_week_id: 0,
                    start_workout_id: 0,
                    add_workouts_in_classroom: 1,
                    start_on_this_week: 0,
                    is_evergreen: 0,
                    show_in_library: 0,
                };

                vm.startingWeeks     = [];
                vm.startingWorkouts  = [];
            },

            handleStartWorkoutPlan (params, { setFieldError }) {
                const vm = this;

                vm.form.setFieldError = setFieldError;
                vm.form.closeModal = vm.closeModal;

                const option = Helper.swalConfirmOptions('Are You Sure?', "You want to enroll workout plan for selected contacts?")

                option.preConfirm = function() { return vm.startWorkoutPlan(vm.form) };

                Swal.fire(option);
            },

            getNextMonday(date = new Date()) {
                return moment(date).tz('UTC').add(1, 'weeks').isoWeekday(1);

                // const dateCopy = new Date(date.getTime());

                // const nextMonday = new Date(
                //     dateCopy.setDate(
                //         dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7),
                //     ),
                // );

                // return nextMonday;
            },

            parseWeeks () {
                const vm = this;
                const plan = vm.workoutPlans.filter(plan => plan.id == vm.form.ids[0]);

                vm.startingWeeks = plan && plan[0] ? plan[0].weeks : [];
            },

            parseWorkouts (id) {
                const vm        = this;
                const week      = vm.startingWeeks.filter(w => w.id == id);
                const workouts  = [];

                if (week && week[0] && week[0].week_settings) {
                    week[0].week_settings.forEach((setting) => {
                        setting.workouts.forEach((workout) => {
                            workouts.push({
                                id: workout.workout_id + '_' + setting.day,
                                name: setting.title.substring(0, 3) + ' - ' + workout.name + ' (' + workout.period + ')'
                            });
                        });
                    });
                }

                vm.startingWorkouts = workouts;
            },

            startOnThisWeekAlert () {
                const vm = this;
                const option = Helper.swalWarningOptions('Alert!', `By starting the plan this week, selected ${vm.form.contacts.length == 1 ? 'contact': 'contacts'} will get the plan assigned to their calendar this week. If the plan starts on a Monday and today is Wednesday, then it will show as missed they will need to complete it late. This won't affect their tracking, but it will show as completed late.`, 'Okay')

                Swal.fire(option);
            }
        },
    }
</script>
